import React from 'react'
import logo from "../../img/logouneleap.svg";
import iphone from "../../img/hero-app.png";
import { Link } from 'react-router-dom';
import appl from '../../img/apple-logo.svg'
import playstore from '../../img/playstore.png'
import phone from '../../img/phone.png' 
import pone from '../../img/Pone.png'
import IOS from '../../img/IOS.png'
import google from '../../img/Google Play.png'
import barcode from '../../img/barcode.png';
import './Download.css';
const download = () => {
  return (
        <>
            <div className='hero_section '>
              <div className=''>
              <div className='sub__hero-section'>
              <Link to="/">
               <img src={logo} alt="" />
              </Link>
              </div>

            <div className="coming__header " >
              <div className="coming__header-content">
              <h5>An Amazing App <br /> That Does It All. </h5>
              <p> A simple touch for faster and better access to all the Uneleap functionalities at anytime and everywhere.</p>
                 <div className='barcode__flex'>
                 <div className='playstore'>
                    <a href="https://play.google.com/store/apps/details?id=com.uneleap.uneleap&pli=1">
                        <img src={google}/> 
                    </a> 
                    <a href='https://apps.apple.com/us/app/uneleap/id1445525039?platform=iphone'>
                       <img src={IOS} />
                      </a>
                </div>
                      <img className='barcode' src={barcode} />
                 </div>
              </div>

              <div className="coming__header-image">
                  <img  src={iphone} />
              </div>
          </div>
          </div>
          </div>

        </>
    
  )
}

export default download
