import React, { useState, useEffect } from 'react'
import {dataRef} from '../Componnet/ambassador/Firebase'
import { storage } from '../Componnet/ambassador/Firebase';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";

import { Navigate, useNavigate } from 'react-router-dom'
function AboutUs  (){
     const [Toptext,setToptext]= useState('');
     const [bottomtext, setBottomtext]= useState('');
      const [filename, setFilename]= useState('');
      const [betext, setbetext] = useState('');
      const [betextone, setbetextone] = useState('');
      const [amazingtext, setamazingtext] = useState('');
      const [amazingtextone, setamazingtextone] = useState('');
      const [peopletext, setpeopletext] = useState('');
      const  [peopletextone, setpeopletextone] = useState('');
      const [percent, setPercent] = useState(0);

  let customfileName =""
  let arg =""
  const [ imageUrl, setImageUrl] = useState([1,2,3])
  let image = ""
      const handleChangeFilename =  (e) => {
        console.log("working")
         customfileName=(e.target.files[0]);
          //  setFilename(customfileName);
           image = customfileName;
          //  arg = "filename"
           console.log("filename" + customfileName);
           console.log("imagefilename" + customfileName.name);
           console.log("image" + image);
           handleUpload (image);
      };

    const handleUpload = (image) => {
      console.log("image: " + image)
      console.log("imagename: " + image.name)
    // event.preventDefault();

    if (!image) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(storage, `/files/${image.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);

      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref)
          .then((url) => {
            console.log("url: " + url);
            // console.log("arg" + arg)
            const updateImageUrl = [...imageUrl, url]
            setImageUrl(updateImageUrl)
            console.log("imageUrl: " + imageUrl);
          } )
      }
    );
  };
     const navigate = useNavigate()
     

     useEffect(() => {
       dataRef.ref("AboutUs").on('value', (snapshot) => {
          const data = snapshot.val();
          console.log("Data", data);
        //  console.log(data)
            setToptext(data.Toptext);
            setBottomtext(data.bottomtext);
            setFilename(data.filename);
            setbetext(data.betext);
            setbetextone(data.betextone);
            // setbef ile(data.befile);
            setamazingtext(data.amazingtext);
            setamazingtextone(data.amazingtextone);
            // setamazingfile(data.setamazingfile);
            setpeopletext(data.peopletext);
            setpeopletextone(data.setbetextone);
            // setpeoplefile(data.peoplefile);
        });
    },[]);
     const handleAdd=()=>{
          // dataRef.ref("AboutUs").set({
          //       Toptext : Toptext,
          //       bottomtext : bottomtext,
          //       filename : filename,
          //       betext : betext,
          //       betextone : betextone,
          //       befile : image,
          //       amazingtext: amazingtext,
          //       amazingtextone : amazingtextone,
          //       amazingfile : image,
          //       peopletext :peopletext,
          //       peopletextone : peopletextone,
          //       peoplefile : image

          //   }).then(()=>{
          //       navigate("/")
          //   });
       console.log("finalimagUrl" +imageUrl)
     }

  return (
      <div className='admin__form-section'>
         <form>
              <h5>Question and Answer!</h5>
            <input 
              type="text" 
              id="fname" 
              name="Toptext" 
              placeholder="Top text" 
              value={Toptext}
          onChange={(e) => setToptext(e.target.value)} />
          {/* <button onClick={handleUpload}>upload</button> */}

            <input 
              type="text" 
              id="lname" 
              name="bottomtext" 
              placeholder="bottom text" 
              value={bottomtext}
          onChange={(e) => setBottomtext(e.target.value)}/>
          
            
            <img src={filename}  />
             <input 
              type="file" 
              accept="image/*"
              // id="myFile" 
              // name="filename" 
          onChange={handleChangeFilename} /> 
             
              <h5>Be Informed!</h5>
            <input 
              type="text" 
              id="fname" 
              name="betext" 
              placeholder="Top text"
              value={betext}
          onChange={(e) => setbetext(e.target.value)} />

             <input 
              type="text"
                id="lname" 
                name="betextone"
                placeholder="bottom text" 
                value={betextone}
          onChange={(e) => setbetextone(e.target.value)} />
           <img src={filename}  />
             <input 
              type="file" 
              accept="image/*"
              // id="myFile" 
              // name="filename" 
          onChange={handleChangeFilename} /> 
             

              <h5>Discover Amazing!</h5>

              <input
               type="text"
                id="fname" 
                name="amazingtext" 
                placeholder="Top text" 
                value={amazingtext}
          onChange={(e) => setamazingtext(e.target.value)} />

              <input 
                type="text"
                id="lname"
                name="amazingtextone" 
                placeholder="bottom text" 
                 value={amazingtextone}
          onChange={(e) => setamazingtextone(e.target.value)}/>

      <img src={filename}  />
                  <input 
                    type="file" 
                    accept="image/*"
                    // id="myFile" 
                    // name="filename" 
                onChange={handleChangeFilename} /> 
             
              <h5> Amazing people!</h5>
            <input
              type="text" 
              id="fname" 
              name="peopletext" 
              placeholder="Top text"
              value={peopletext}
          onChange={(e) => setpeopletext(e.target.value)} />

              <input 
                type="text"
                id="lname" 
                name="peopletextone" 
                placeholder="bottom text" 
                value={peopletextone}
              onChange={(e) => setpeopletextone(e.target.value)}  />
              
            <img src={filename}  />
                      <input 
                        type="file" 
                        accept="image/*"
                        // id="myFile" 
                        // name="filename" 
                    onChange={handleChangeFilename} /> 
                      
             <a href="#"  onClick={handleAdd} className="contact-btn">SUBMIT</a>
         </form>
         </div>
  )
}

export default AboutUs;