import React, {Fragment, useState} from 'react'
import    './Help.css';
import WorkDetail from '../../Data/WorkDetail'
import aboutus from '../../img/about_04.jpg';
import Question from '../../Data/Accordion';
import Collapsible from 'react-collapsible';
import iphone from "../../img/hero-app.png";
import Faq from '../faq/Faq';

const Help = () => {
    const [detail, setDetail] = useState(WorkDetail)
  return (
    <div>
       <Fragment>
         <div className='input__section'>
         
          <div className='input_one'>
             <h3>Uneleap shortcuts.</h3> 
             <input type="text" name=""  placeholder='type here'/>
             <button>Search</button>
          </div>
          <section className='work'>
            <div className='overlays'></div>
               <div className='work-container'>
                 <div className='work-heading'>
                     <span>work flow</span>
                     <h2>How it Work</h2>
                    </div>
                   <div className='colum1'>
                        <div className='work-row'>
                          {
                            detail.map((detail) => {
                               return(
                            <div className='work-card'>
                                <div className='work-column'>
                                     <div className='content'>
                                          <div className='number'>
                                             <span>{detail.number}</span>
                                           </div> 
                                       <div className='work-content'>
                                             <h3>{detail.heading}</h3>
                                             <p>{detail.info}</p>
                                      </div>
                                   </div>
                               </div>
                             </div>
                               )
                            })
                          }
                        </div>
                   </div>
                </div>
          </section>
          </div>
       </Fragment>
        <Faq />
    </div>
  )
}

export default Help
