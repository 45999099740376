import React from 'react'
import video from '../../video/hero.mp4';
import logo from "../../img/logouneleap.svg";
import { Link } from "react-router-dom";
const Home = () => {
  return (
      <div>
            <div className="Hero">
      <header>
        <h2 class="logo">
          <Link to="/">
          <img src={logo} alt="" />
          </Link>
        </h2>
        <div className="container">
             {/* <button className="btn-1 btn">CollegeFest "22 <span></span></button> */}
        </div>
      </header>
      <video loop
          muted
          autoPlay
          playsinline >
        <source src={video} type="video/mp4" />
      </video>
      <div class="overlay"></div>
      <div class="text">
        <div class="wrapper">
            <span className="arrow_down">
            <i class="fa-solid fa-arrow-down-long"></i></span>
            <div className="hero_coming">
             <Link to="/Download">
                  <span className="stroke-text"> Uneleap </span>
             </Link>
           </div>
          <nav class="main-nav">
            <ul>
                <p>
                 <a class="links color-one" target="_blank" href="https://buddysafe.app/">BuddySafe</a>
                </p>

                <p>
                 <a class="links color-two" target="_blank" href="#"> Borderless</a>
                </p>
                
                <p>
                 <a class="links color-three" target="_blank" href="https://faithmeetslove.com/">F M L</a>
                </p>

                <p>
                   <a class="links color-four" target="_blank" href="#"> AFEX</a>
                </p>
            </ul>
          </nav>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Home