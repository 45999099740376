const WorkDetail = [
    {
        id: "1",
        number: "01",
        heading: "Basics",
        info: "Uneleap provide all users the basice informations how to get interships and also graduat jobs."
    },
    {
        id: "2",
        number: "02",
        heading: "Daily News",
        info: "Uneleap provide all users the basice informations how to get interships and also graduat jobs."
    },
    {
        id: "3",
        number: "03",
        heading: "Your profile",
        info: "Uneleap provide all users the basice informations how to get interships and also graduat jobs."
    },
    {
        id: "4",
        number: "04",
        heading: "Subscription",
        info: "Uneleap provide all users the basice informations how to get interships and also graduat jobs."
    },
]
export default WorkDetail
