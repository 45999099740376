import React from 'react'
import aboutus from '../../img/uneleap-img-three.png';
import Safetyone from '../../img/uneleap-img-three.png';
import Safetytwo from '../../img/uneleap-img-two.png';
import './Safety.css'
const Safety = () => {
  return (
    <div className='safety_container'>
      <main className="safety-us-section">
      <div className="safety-img-container">
           <img src={aboutus} alt="" />
        </div>
        <div className="safety-text-container">
            <h3>Let's work together to keep
                your account secure</h3>
            <p>Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis nibh sed<br />
            tellus faucibus bibendum. Sed fermentum est vitae rhoncus molestie. Cum<br />
            sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus<br />
            mus. Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam.<br />
            Donec ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
                <p>Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam. Donec<br/>
                ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
        </div>
    </main>
    <main className="safety-us-section">
        <div className="safety-text-container">
            <h3>Let's work together to keep
                your account secure</h3>
            <p>Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis nibh sed<br />
            tellus faucibus bibendum. Sed fermentum est vitae rhoncus molestie. Cum<br />
            sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus<br />
            mus. Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam.<br />
            Donec ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
                <p>Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam. Donec<br/>
                ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
        </div>
        <div className="safety-img-container">
           <img src={aboutus} alt="" />
        </div>
    </main>
    <main className="safety-us-section">
     <div className="safety-img-container">
           <img src={Safetyone} alt="" />
        </div>
        <div className="safety-text-container">
            <h3>What are best practices for my account security?</h3>
            <p>Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis nibh sed<br />
            tellus faucibus bibendum. Sed fermentum est vitae rhoncus molestie. Cum<br />
            sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus<br />
            mus. Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam.<br />
            Donec ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
                <p>Sed vitae rutrum neque. Ut id erat sit amet libero bibendum aliquam. Donec<br/>
                ac egestas libero, eu bibendum risus. Phasellus et congue justo.</p>
        </div>
    </main>
    </div>
  )
}

export default Safety