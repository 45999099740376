const question =[
    {
        id:"1",
        question:"How many team members can I invite?",
        answer:"You can invite up to 2 additional users on the Free plan. There is no limit on team members for the Premium plan"
    },
    {
        id:"2",
        question:"How do I reset my password?",
        answer:"Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you"
    },
    {
        id:"3",
        question:"How do I reset my password?",
        answer:"Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you"
    },
    {
        id:"4",
        question:"Can I cancel my subscription?",
        answer:"Click “Forgot password” from the login page or “Change password” from your profile page. A reset link will be emailed to you."
    },
    {
        id:"4",
        question:"Do you provide additional support?",
        answer:"Chat and email support is available 24/7. Phone lines are open during normal business hours."
    },
    
]
export default question