import image1 from "../img/blog1.jpg";
import internone from "../img/intern-one.jpg"
import interntwo from "../img/intern-two.jpg"
import internthree from "../img/intership-three.jpg"

export const blogdata = [
    {
        id:"1",
        image: internone,
        heading:"Kickstarting my career with multiple internships at start-ups and Samsung",
        span:"About the author: Hillary peter is a 3rd-year UNN student at university of nigeria, nsukka. He started interning at the end of his first year and shares how he...",
      },
      {
        id:"1",
        image: internone,
        heading:"How I am making a career in design as an engineering student",
        span:"Okwigbedi Godwin is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      },
      {
        id:"1",
        image: interntwo,
        heading:"How I am making a career in design as an engineering student",
        span:"Okwigbedi Godwin is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      },
      {
        id:"1",
        image: internthree,
        heading:"How I am making a career in design as an engineering student",
        span:"Ben Great is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      },
      {
        id:"1",
        image: internthree,
        heading:"How I am making a career in design as an engineering student",
        span:"Godwin joe is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      },
      {
        id:"1",
        image: internthree,
        heading:"How I am making a career in design as an engineering student",
        span:"Smart wast is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      },
      {
        id:"1",
        image: internthree,
        heading:"How I am making a career in design as an engineering student",
        span:"Smart wast is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      }, {
        id:"1",
        image: internthree,
        heading:"How I am making a career in design as an engineering student",
        span:"Smart wast is a third-year engineering student covenant university, Nigeria. He shares how he uncovered and pursued his interest in design through internships.",
      }
]