import React from 'react'
import { Link } from 'react-router-dom'
import appl from '../../img/apple-logo.svg'
import playstore from '../../img/playstore.png'
import phone from '../../img/phone.png' 
import iphone from "../../img/hero-app.png";
import foot from '../../img/footerlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram } from '@fortawesome/free-solid-svg-icons'
import './footer.css'

const footer = () => {
  return (
     <div>

      <div className='footer__section'>
         <div>
            <div className='footer__socials'>
               <Link to="/">
                   <img  src={foot}  />
             </Link>
          <ul className="socials">
                <span><a href="https://www.facebook.com/FaithMeetsLove/" target="_blank"><i className="fab fa-facebook-f facebook"></i></a></span>
            <span><a href="https://twitter.com/Faithm33tslove" target="_blank"><i className="fab fa-twitter twitter"></i></a></span>
            <span><a href="https://www.linkedin.com/company/faithmeetslove/" target="_blank"><i className="fab fa-linkedin-in linkedin"></i></a></span>
            <span><a href="https://www.instagram.com/faithmeetslove/" target="_blank"><i className="fab fa-instagram instagram"></i></a></span>
         </ul>
         </div>
      </div>
    <div>
      <ul className='footer__list'>
            <Link to="/About">
               <li>About</li>
            </Link>
            <Link to="/Download">
            <li>Download</li>
            </Link>
            <Link  to="/Blog">
               <li>Blog</li>
            </Link>
            <Link  to="/Support">
            <li>Support</li>
            </Link>
            <Link to="/Help">
              <li>Help</li>
            </Link>
            <Link to="/Safety">
              <li>Safety</li>
            </Link>
            <Link to="/Ambassador">
                 <li>Ambassador</li>
            </Link>
         </ul>
      </div>
    </div>
    </div>
  )
}

export default footer