// import React from 'react'
import React ,{Fragment, useState} from 'react'
import './Blog.css';
import { blogdata } from '../../Data/blogdata';
import { Link } from 'react-router-dom'
const Blog = () => {
  const [blog, setBlog] = useState(blogdata)
  return (
      <>
        <section className='blog'>
                <div className='center'>
                    <h3>Latest Blog News</h3>
                    <p>recent blogs about Uneleap</p>
                </div>
      <div className='row'>
           {blog.map((blogs)=>(
                         <div className='column'>
                         <div className='single-blog'>
                            <Link to="/Subblog">
                               <div className='card'>

                                   <div className='blog-thumb'>
                                         <div className='blog-tag'>Blog News</div>
                                         <img src={blogs.image} alt='' />
                                   </div>
                                   <div className='blog-content'>
                                          <h3>{blogs.heading}</h3>  
                                          <hr />
                                          <div className='mark'>
                                              <span>{blogs.span}</span>
                                         </div> 
                                   </div>
                               </div>
                            </Link>
                         </div>
                   </div>
           ))}
      </div>
      </section>
      </>  
  )
}


export default Blog