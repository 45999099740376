import React, { useState } from 'react'
import './Support.css';
import iphone from "../../img/hero-app.png";
import whiteTick from '../../img/whiteTick.png'
import {plansData} from '../../Data/plansData'
import security from '../../img/security.jpg';
import { Link } from 'react-router-dom';
import { dataRef } from '../ambassador/Firebase';
import { Navigate, useNavigate } from 'react-router-dom'
function Support  (){
     const [firstName,setfirstName]= useState('');
     const [lastName,setlastName]= useState('');
     const [email, setEmail]= useState('');
     const [massage, setMassage]= useState('')

     const navigate = useNavigate()

     const handleAdd=()=>{
          dataRef.ref("Contact us").push({
                firstName : firstName,
                lastName : lastName,
                email : email,
                massage : massage,
            }).then(()=>{
                navigate("/")
            });
      
     }
  return (
       <> 
        <div className='Support-header'>
            <h3>Welcome! We are here to Help.</h3>
        </div>
             <div className='Support__list'>
                <div className='iphone'>
                   <img src={security} />
                </div>
                <div className='list-one'>
                     <h3>Quick Support Pages for Our Users. </h3>
                     <p>Our commitment is to help every user the best way we can.</p>
                        <div className='suppot__list'>
                              <div className='p__text'>
                              <Link className='link' to="/Cookie">
                                  <p>Cookie policy</p>
                                  </Link>
                              </div>
                              <div className='p__text'>
                              <Link className='link' to="/Privacy">
                                  <p>privacy policy</p>
                                  </Link>
                              </div>
                              <div className='p__text'>
                              <Link className='link' to="/Eula">
                                  <p>Eula policy</p>
                                  </Link>
                              </div>
                              <div className='p__text'>
                              <Link className='link' to="/Professional">
                                  <p>Professional policy</p>
                                  </Link>
                              </div>
                              <div className='p__text'>
                              <Link className='link' to="/Terms">
                                  <p>Terms policy</p>
                                  </Link>
                              </div>
                        </div>
                    </div>
                </div>
     <section className="contact-section">
    <h2>Contact Us</h2>
    <p>We Might Be An App But We Don't Leave Our Customers Hanging. 
        Everything About Us Is On The App, But If You Ever Need Help (Or You Just Want To Chat), 
        We’ve Got You.!</p>
    <div className="contact-container">
        <div className="details">
            <div className="detail">
                <i className="uil uil-mobile-android"></i>
                <h3>CALL US</h3>
                 <p>+44 000-000-000</p>
            </div>
            <div className="detail">
                 <a href="mailto:techsupport@Uneleap.com">
                 <i className="uil uil-envelopes"></i>
                  <h3>EMAIL US</h3>
                 <p>techsupport@Uneleap.com</p>
                  </a>
            </div>
            <div className="detail">
                <i className="uil uil-location-pin-alt"></i>
                <h3>VISIT OFFICE</h3>
                 <p>United Kingdom</p>
            </div>
        </div>
        <form id="Form" action="#">
            <div className="input-name">
                <input type="text" placeholder="First Name" value={firstName} onChange={(e)=> {setfirstName(e.target.value)}}/>
                <input type="text" placeholder="Last Name" value={lastName}onChange={(e)=> {setlastName(e.target.value)}}/>
            </div><input type="email" placeholder="Your Email" value={email} onChange={(e)=> {setEmail(e.target.value)}}/>
             <textarea id="" cols="20" rows="5" placeholder="Your Massage" value={massage} onChange={(e)=> {setMassage(e.target.value)}}>
                         </textarea>
           <a href="#"  onClick={handleAdd} className="contact-btn">SUBMIT</a>
        </form>
    </div>
 </section>
     </>
  )
}

export default Support