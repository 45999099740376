import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Studentimg from '../../img/studentimg.jpg'
import { dataRef } from './Firebase'
import './Ambassador.css'
function Ambassador  (){
     const [name,setName]= useState('');
     const [email, setEmail]= useState('');
     const [twitter, setTwitter]= useState('');
     const [linkedln, setLinkedln]= useState('')
     const [facebook, setFacebook]= useState('')
     const [instagram, setInstagram] = useState('')
     const [massage, setMassage]= useState('')

     const navigate = useNavigate()

     const handleAdd=()=>{
          dataRef.ref("Ambassador").push({
                name : name,
                email : email,
                twitter : twitter,
                linkedln : linkedln,
                facebook : facebook,
                instagram : instagram,
                massage : massage,
            }).then(()=>{
                navigate("/")
            });
      
     }

  return (
       <div className='Ambassador'>
            <div className='Ambassador__container'>
                <div className='Ambassador__img-section'>
                     <img src={Studentimg} />
                </div>
                <div className='Ambassador__form-section'>
                    <div className='Ambassador__form-text'>
                         <h2>We are Uneleap!</h2>
                         <p>Sign-Up To Be a Uneleap Ambassador.</p>
                    </div>
                <form id="Form" action="#">
                        <input  type="text" placeholder="Your FullName" value={name} onChange={(e)=> {setName(e.target.value)}}/>
                        <input type="email" placeholder="Your Email" value={email} onChange={(e)=> {setEmail(e.target.value)}} />
                        <input type="text" placeholder="twitter handle" value={twitter} onChange={(e)=> {setTwitter(e.target.value)}}/>
                        <input type="text" placeholder="linkedIn handle" value={linkedln} onChange={(e)=> {setLinkedln(e.target.value)}}/>
                        <input type="text" placeholder="Facebook handle" value={facebook} onChange={(e)=> {setFacebook(e.target.value)}}/>
                        <input type="text" placeholder=" instagram handle" value={instagram} onChange={(e)=> {setInstagram(e.target.value)}}/>
                         <textarea id="" cols="20" rows="5" placeholder="Your Massage" value={massage} onChange={(e)=> {setMassage(e.target.value)}}>
                         </textarea>
                        <a href="#"  onClick={handleAdd} className="contact-btn">SUBMIT</a>
                  </form>
                </div>
            </div>
       </div>
  )
}

export default Ambassador;

