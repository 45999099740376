
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { getStorage } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyC1AzGhGjS5m0PrKpg_bz7wcb3-WpGzgzc",
    authDomain: "uneleap-9b858.firebaseapp.com",
    databaseURL: "https://uneleap-ambassadors.firebaseio.com/",
    projectId: "uneleap-9b858",
    storageBucket: "ambassador-9658b.appspot.com",
    messagingSenderId: "399698159769",
    appId: "1:399698159769:web:32628b69175e083542b008",
    measurementId: "G-M0DL0DJRJG"
  };

  // firebase.initializeApp(firebaseConfig);
  const app = firebase.initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const dataRef=firebase.database();

 export {
      storage,
      dataRef
 };