import React from 'react'
import iphone from "../../img/hero-app.png";
import appl from '../../img/apple-logo.svg'
import playstore from '../../img/playstore.png'
import phone from '../../img/phone.png' 
import excellence from '../../img/excellence.svg';
import growth from '../../img/growth.svg';
import support from '../../img/support.svg';
import transparency from '../../img/transparency.svg';
import aboutus from '../../img/about_04.jpg';
import  iphoneone from '../../img/iphone-feature-03 (1).png'
import pone from '../../img/Pone.png'
import iphonefeature from '../../img/iphone-feature-01.png'
import iphonefeatureone from '../../img/iphone-feature-02.png'
import iphonefeaturetwo from '../../img/iphone-feature-03.png'
import iphonefeaturethree from '../../img/iphone-feature-04.png'
import iphonefeaturefour from '../../img/iphone-feature-05.png'
import iphonefeaturefive from '../../img/iphone-feature-06.png'
// import { dataRef } from '../Ambassador/Firebase';
import './About.css';


const About = () => {
   //    const [aboutData, setAboutData] = useState([])
   //    console.log("dataref", dataRef)
   //   useEffect(()=> {
   //      dataRef.ref("Aboutus").on("value", (snapshot)=> {
   //          const data = snapshot.val();
   //          console.log("AboutDa", data);
   //          setAboutData(data);
   //      });
   //  },[])
  return (
     <div>
      <main >
          <div className='containers headers__container'>
               <div className='headers__left'>
                  <h2>Questions and Answers!</h2>
                  <p>Exchange ideas with students, alumni and faculties around the world on the Uneleap App!</p>
                  <a href='#' className='btno'></a>
               </div>
               <div className='headers__right'>
                        <div className='headers__right-image'>
                              <img src={iphonefeature} />
                        </div>
               </div>
          </div>

          <div className='about__section'>
              
               <div className='About__images_section'>
                        <div className='about__img'>
                              <img src={iphonefeatureone} />
                        </div>
               </div>
               <div className='about__text'>
                  <h2>Be Informed!</h2>
                  <p>Exchange ideas with students, alumni and faculties around the world on the Uneleap App!</p>
                  <a href='#' className='btno'></a>
               </div>
          </div>

          <div className='containers headers__container'>
               <div className='headers__left'>
                  <h2>Discover Amazing!</h2>
                  <p>Exchange ideas with students, alumni and faculties around the world on the Uneleap App!</p>
                  <a href='#' className='btno'></a>
               </div>
               <div className='headers__right'>
                        <div className='headers__right-image'>
                              <img src={iphonefeaturetwo} />
                        </div>
               </div>
          </div>

          <div className='about__section'>
              
              <div className='About__images_section'>
                       <div className='about__img'>
                             <img src={iphonefeaturethree} />
                       </div>
              </div>
              <div className='about__text'>
                 <h2>Amazing People!</h2>
                 <p>Exchange ideas with students, alumni and faculties around the world on the Uneleap App!</p>
                 <a href='#' className='btno'></a>
              </div>
         </div>
         
          
         <div className='containers headers__container'>
               <div className='headers__left'>
                  <h2>Find and Replace!</h2>
                  <p>Exchange ideas with students, alumni and faculties around the world on the Uneleap App!</p>
                  <a href='#' className='btno'></a>
               </div>
               <div className='headers__right'>
                        <div className='headers__right-image'>
                              <img src={iphonefeaturefour} />
                        </div>
               </div>
          </div>
    </main>
   
      <div className='belive__section'>
            
            <div className='support__section'>
                <div className='flex__about'>
                   <img src={excellence} />
                   <h3>Excellence</h3>
                </div>
                <div className='flex__about'>
                   <img src={growth} />
                   <h3>Growth</h3>
                </div>
                <div className='flex__about'>
                   <img src={support} />
                   <h3>Support</h3>
                </div>
                <div className='flex__about'>
                   <img src={ transparency } />
                   <h3> Transparency </h3>
                </div>
            </div>
        </div>

        {/* <div className='footer-flex'>
            <div className='sub-footer'>
                <h5>Sign up for free</h5>
                <p className='download-text'>Download the Pennee mobile app and <br/> experience growth in your business</p>
                 <div className='footer-flex-icon'>
                 <div className='flex-one'>
                      <img src={google}/>
                      <img src={IOS} />
                </div>
                </div>
                </div>
                <div className='footer-img'>
                    <img src={pone} />
                </div>
        </div> */}
     </div>
  )
}

export default About