import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Componnet/Home/Home';
import Footer from './Componnet/footer/Footer';
import Eula from './Componnet/eula/Eula';
import About from './Componnet/about/About';
import Support from './Componnet/support/Support';
import Cookie from './Componnet/cookie/Cookie';
import Privacy from './Componnet/privacy/Privacy';
import Help from './Componnet/help/Help';
import Blog from './Componnet/blog/Blog';
import Professional from './Componnet/professional/Professional';
import Terms from './Componnet/terms/Terms';
import Download from './Componnet/download/Download';
import Safety from './Componnet/safety/Safety'
import Subblog from './Componnet/subblog/Subblog';
import Ambassador from './Componnet/ambassador/Ambassador';
// import Sidebar from './Admin/componnets/Sidebar';
import Dashboard from './pages/Dashboard'
// import About from './pages/About'
import Comment from './pages/Comment'
import Project from './pages/Project'
import Analytics from './pages/Analytics'
import Linking from './Linking.js/Linking'
import WebsiteLayout from './layouts/Website';
function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="" element={<WebsiteLayout />}>
						<Route path="/" element={<Home />} />
						<Route path="/footer" element={<Footer />}></Route>
						<Route path="/eula" element={<Eula />}></Route>
						<Route path="/about" element={<About />}></Route>
						<Route path="/support" element={<Support />}></Route>
						<Route path="/cookie" element={<Cookie />}></Route>
						<Route path="/privacy" element={<Privacy />}></Route>
						<Route path="/help" element={<Help />}></Route>
						<Route path="/blog" element={<Blog />}></Route>
						<Route path="/professional" element={<Professional />}></Route>
						<Route path="/terms" element={<Terms />}></Route>
						<Route path="/download" element={<Download />}></Route>
						<Route path="/safety" element={<Safety />}></Route>
						<Route path='/subblog' element={<Subblog />}></Route>
						<Route path='/ambassador' element={<Ambassador />}></Route>
					</Route>
					{/* <Route path="/dashadmin" element={<Linking />}>
						<Route path="" element={<Dashboard />} />
						<Route path="comment" element={<Comment />} />
						<Route path="analytics" element={<Analytics />} />
						<Route path="project" element={<Project />} />
					</Route> */}
				</Routes>
			</Router>
		</div>
	);
}

export default App;
