import React from 'react'
import './Professional.css';
const Professional = () => {
  return (
      <div className='professional'>
            
            <div className='professional__section'>
                  <div className='professional__section-one'>
                        <h4>Professional Community Policy</h4> 
                        <p>This is a community where we treat each other with respect and help each other succeed.</p> 
                  </div>
                  <div className='professional__width'>
                  <div className='professional__section-two'>
                      <span>Effective January 1, 2022</span>
                        <h5>Uneleap Professional Community Policies</h5>
                        <p>Thank you for using Uneleap, where the world’s 
                      professionals come together to find jobs, stay informed, learn new skills, 
                      and build productive relationships. The content that you contribute to
                       Uneleap should be professionally relevant and meant
                       to contribute to the Uneleap community in a constructive manner.These Community Policies continually evolve to ensure our members have a safe, trusted, and professional experience on Uneleap</p>
                  </div>
                  <div className='professional__section-two'>
                         <h5>Be safe</h5>
                         <p>We don’t allow content that attacks, denigrates, 
                           intimidates, dehumanizes, incites or threatens hatred, violence,
                            prejudicial or discriminatory action against individuals or groups because 
                            of their actual or perceived race, ethnicity, national origin, caste, gender,
                             gender identity, sexual orientation, religious affiliation, or disability status. Hate groups are not permitted on LinkedIn. Do not use racial, religious, or other slurs that
                            incite or promote hatred, or any other content intended to create division.
                            We don’t allow unwanted expressions of attraction, desire,
                             requests for a romantic relationship, marriage proposals, sexual
                              advances or innuendo, or lewd remarks. Do not use LinkedIn to pursue
                               romantic connections, ask for 
                            romantic dates, or provide sexual commentary on someone’s appearance. </p>
                   </div>
                   <div className='professional__section-two'>
                        <h5>Be Trustworthy</h5>
                       <p>Do not share content in a way that you know is, or think may be,
                            misleading or inaccurate, including misinformation or disinformation.
                             Do not share content to interfere with or improperly influence an election
                              or another civic process. We may prevent you from posting content from sites 
                              that are known to produce or contain misinformation. Do not share content that
                               directly contradicts guidance from leading global health organizations and
                                public health authorities. Do not post content that denies a well-documented 
                                historical event such as the Holocaust or slavery. Do not share false content
                                 or information, including news stories, that present untrue facts or events 
                                 as though they are true or likely true. Do not post “deepfake” images or
                                  videos of others or otherwise post content that has been manipulated to
                                   deceive. Do not share content or endorse someone or something in exchange 
                                   for personal benefit (including personal or family relationships, monetary
                                    payment, free products or services, or other value), unless you have
                                     included a clear and conspicuous notice of the personal
                            benefit you receive and have otherwise complied with our Advertising Policies.  </p>
                  </div>
                  <div className='professional__section-two'>
                   <h5>Be professional</h5>
                        <p> We require content to be professionally relevant and on topic, such as sharing and gaining expertise; hiring and getting hired; teaching and acquiring new skills; and engaging in actions that allow you and others to be more productive and successful.</p>
                  </div>
            </div>
            </div>
      </div>
  )
}

export default Professional