import React from 'react'
import { Outlet } from "react-router-dom";
import Footer from '../Componnet/footer/Footer';

const WebsiteLayout = () => {
	return (
		<div>
			<Outlet />
			<Footer />
		</div>
	)
}

export default WebsiteLayout