import React, {Fragment, useState} from 'react'
import aboutus from '../../img/about_04.jpg';
import Question from '../../Data/Accordion';
import iphone from "../../img/hero-app.png";
import Collapsible from 'react-collapsible';
import './Faq.css';
const Faq = () => {
    const [detail, setDetail] = useState(Question)
  return (
    <div>
         <Fragment>
          <section className='accordion'>
               <div className='accordion-row'>
                  <div className='left'>
                     <h3>Frequently Ask</h3>
                     {
                       detail.map((detail) => {
                          return (
                             <div className='left-content'>
                                  <Collapsible
                                  className='Collapsible'
                                  trigger={[<i class="fa-solid fa-angle-down"></i>, detail.question]}
                                  triggerWhenOpen = {[<i class="fa-solid fa-angle-up"></i>, detail.question]}
                                  >
                                    <p className='conent-show'>
                                      {detail.answer}
                                    </p>
                                  </Collapsible>
                             </div>
                          )
                       })
                     }
                  </div> 
                  <div className='right'>
                       <img src={iphone} alt='person'/>
                  </div>                        
               </div>
          </section>
         </Fragment>
    </div>
  )
}

export default Faq