import React from 'react'
import  './Subblog.css'
import post from '../../img/post-2.png'
import intership from '../../img/intership-three.jpg'
const Subblog = () => {
  return (
    <div className="container-page">
			
            <div className="container-blog">
				
				<div className="title-blog">
					<h1>Uneleap Blog</h1>
                    <p>Written by Aaron Jack who lives
                        wherever he wants, and works 
                        whenever he wants. Check out his
                    </p>
				</div>
				
				<div className="post-top">
            		<img className='interss' src={post} />
					<div className="post-details post-top-details">
						<h3>How to make your web design unique</h3>
						<h6>What makes visual content unique &
							how to create it without breaking the rules.</h6>
						
						<div className="bio">
							<img src={post} />
							<div>
								<p>Aaron Jack</p>
								<p>May 10 - 12 min read</p>
							</div>
							
						</div>
					</div>
                </div>
				
				<div className="posts-other">
					
					<div className="post-other">
						<img src={post} />
						<div className="post-details">
							<h3>placement at GTB</h3>
							<h6>When does your business need a landing page?</h6>
							
							<div className="bio">
								<img src={post} />
								<div>
									<p>Aaron Jack</p>
									<p>May 10 - 12 min read</p>
								</div>
								
							</div>
						</div>
					</div>
						
					<div className="post-other">
						<img src={post} />
						<div className="post-details">
            <h3>placement at GTB</h3>
							<h6>Anatomy of an effective dashboard</h6>
							
							<div className="bio">
								<img src={post} />
								<div>
									<p>Aaron Jack</p>
									<p>May 10 - 12 min read</p>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="post-other">
						<img src={post} />
						<div className="post-details">
					    	<h3>placement at GTB</h3>
							<h6>Secrets uncovered after years in the industry</h6>
							
							<div className="bio">
								<img src={post} />
								<div>
									<p>Aaron Jack</p>
									<p>May 10 - 12 min read</p>
								</div>
								
							</div>
						</div>
					</div>
								
				</div>
            </div>
        </div>
  )
}

export default Subblog
